import { inject, Injectable } from '@angular/core';
import { ConfirmationService, Message, MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  messageService = inject(MessageService);
  confirmService = inject(ConfirmationService);

  public warnSnack(message: string) {
    this.messageService.add({
      severity: 'warn',
      summary: 'Warning',
      detail: message,
    });
  }

  public successSnack(message: string) {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: message,
    });
  }

  public errorSnack(message: string) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message,
    });
  }

  public infoSnack(message: string) {
    this.messageService.add({
      severity: 'info',
      summary: 'Info',
      detail: message,
    });
  }

  public showConfirmationPopup(options: {
    target: EventTarget;
    message: string;
    accept?: Function;
    reject?: Function;
    acceptLabel?: string;
    rejectLabel?: string;
    classStyle?: string;
  }) {
    return this.confirmService.confirm({
      target: options.target,
      message: options.message,
      accept: options.accept,
      reject: options.reject,
      acceptLabel: options.acceptLabel,
      rejectLabel: options.rejectLabel,
      rejectButtonStyleClass: options.classStyle,
    });
  }

  public actionSnack(data?: Message) {
    this.messageService.add({});
  }
}
